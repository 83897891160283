// import { TRACKING_EVENTS } from '../../../shared/constants';

export const storeItem = (key, item) => {
  if (typeof localStorage !== 'undefined' && localStorage) {
    try {
      return localStorage.setItem(key, JSON.stringify(item));
    } catch (err) {
      console.error(`Error storing item ${key} to localStorage`, err);
    }
  }
};

export const getItem = (key) => {
  if (typeof localStorage !== 'undefined' && localStorage) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (err) {
      console.error(`Error getting item ${key} from localStorage`, err);
    }
  }
};

export const createDevfolioQuizObject = (quizId) => {
  storeItem('devfolio-quiz', { quizId });
};

const updateLocation = (href) => {
  if (typeof window !== 'undefined') {
    window.location.href = href;
  }
};

/**
 * This constant provides the paths to the pages which are used
 * as hrefs. For instances where the routing happens via a button
 * or in an effect, the helper methods below are used.
 */
export const ROUTES = {
  SIGN_IN: '/signin',
  DASHBOARD: '/hackathons/applied',
  ORGANIZE: '/organize',
  SUBMISSIONS: (slug) => `https://${slug}.devfolio.co/submissions`,
  PUBLIC_PAGE: (slug) => `https://${slug}.devfolio.co`,
};

/**
 * In addition to the {@link ROUTES} constant which is used for link hrefs,
 * these helpers are used in either button event handlers or in useEffects
 */
export const routeToStartQuiz = () => {
  // trackEvent(TRACKING_EVENTS.landingPageTopCTA);
  updateLocation('/quiz/start');
};

export const routeToDashboard = () => {
  updateLocation('/hackathons/applied');
};

export const routeToHome = () => {
  setTimeout(() => {
    updateLocation('/home');
  }, 1000);
};

export const routeToSignUp = (email) => {
  if (typeof email === 'string') {
    updateLocation(`/signup?email=${encodeURIComponent(email)}`);
  } else {
    updateLocation(`/signup`);
  }
};

export const routeToHackathons = (slug) => {
  updateLocation(`/hackathons`);
};
